import React from 'react';

import { useLogin } from './hooks/useLogin';

import { ArrowIcon } from '@assets/image/svg/ArrowIcon';
import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { CheckBox } from '@components/controls/checkbox';
import { Card } from '@components/layout/card';
import { Center } from '@components/layout/center';
import { HStack } from '@components/layout/hstack';
import { VStack } from '@components/layout/vstack';
import { Anchor } from '@components/typographies/anchor';
import { ErrorMessage } from '@components/typographies/error';
import { Heading } from '@components/typographies/heading';
import { useRemindme } from '@redux/slices/remindmeSlice';

export const Login = () => {
  const { onSubmit, errors, onChangeEmail, onChangePassword, apiError } = useLogin();
  const { remindme, onChangeRemindme } = useRemindme();

  const emailError = errors.find((e) => e.field === 'email');
  const passError = errors.find((e) => e.field === 'password');

  return (
    <Card
      py="48px"
      height="max-content"
      mx="30px"
      md={{ width: '480px', margin: 'auto' }}
      bg="skin"
    >
      <VStack as="form" onSubmit={onSubmit} width="100%" spacing="15px">
        <Heading align="center">メールアドレスでログイン</Heading>
        <VStack spacing="10px" width="274px" align="flex-start">
          <Input
            id="email"
            type="email"
            autoComplete={remindme ? 'email' : 'off'}
            onChange={onChangeEmail}
            placeholder="メールアドレス"
          />
          {emailError && <ErrorMessage>{emailError.message}</ErrorMessage>}
          <Input
            id="password"
            type="password"
            autoComplete={remindme ? 'current-password' : 'off'}
            onChange={onChangePassword}
            placeholder="パスワード"
          />
          {apiError && <ErrorMessage>{apiError.getError()}</ErrorMessage>}
          <CheckBox
            checked={remindme}
            onChange={onChangeRemindme}
            id="remindme"
            placeholder="ログイン情報を保存する"
          />
          <HStack spacing="5px">
            <Center width="11px" height="14.5px">
              <ArrowIcon />
            </Center>
            <Anchor href={'/reset-password'}>パスワードを忘れた方はこちら</Anchor>
          </HStack>
        </VStack>
        <Button width="210px" height="35px" type="submit">
          ログインする
        </Button>
      </VStack>
      {/* <Span
        display="block"
        width="100%"
        height="1px"
        background="var(--color-border-gray)"
        marginTop="35px"
        marginBottom="35px"
      />
      <Center height="42px">
        <Heading mb="20px">SNSアカウントでログイン</Heading>
      </Center>
      <VStack spacing="9px">
        <button type="button">
          <GoogleLoginIcon />
        </button>
        <button type="button">
          <TwitterLoginIcon />
        </button>
        <button type="button">
          <FacebookLoginIcon />
        </button>
      </VStack>
      <Span display="block" height="10px" /> */}
    </Card>
  );
};
