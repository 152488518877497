import { navigate } from 'gatsby';
import { ComponentProps, useState } from 'react';
import { z } from 'zod';

import { useAuth } from '@hooks/useAuth';
import { ErrorResponse } from '@pb/api/error_pb';

const LoginSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'メールアドレスを入力してください' })
    .email({ message: '不正なメールアドレスです' }),
});

type LoginError = {
  field: 'email' | 'password';
  code: string;
  message: string;
};

export const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<LoginError[]>([]);
  const [apiError, setAPIError] = useState<ErrorResponse>();
  // add i18n and set redirect after login
  const [_, { login }] = useAuth();

  // const onSubmit: ComponentProps<'form'>['onSubmit'] = useSuspensePromise(
  //   async (e) => {
  //     e.preventDefault();
  //     const result = LoginSchema.safeParse({ email, password });
  //     if (!result.success) {
  //       setErrors(result.error.issues.flatMap((i) => i.path.map((field) => ({ field, ...i } as LoginError))));
  //       return;
  //     }

  //     await login({ email, password });
  //     navigate('/');
  //   },
  //   (e) => {
  //     console.error(e);
  //     setErrors([
  //       {
  //         field: 'email',
  //         code: 'login_error',
  //         message: 'メールアドレスまたはパスワードが間違っています',
  //       },
  //     ]);
  //   }
  // );

  const onSubmit: ComponentProps<'form'>['onSubmit'] = async (e) => {
    e.preventDefault();
    await login({ email, password })
      .then(() => {
        navigate('/');
      })
      .catch((e: ErrorResponse) => {
        setAPIError(e);
      });
  };

  const onChangeEmail: ComponentProps<'input'>['onChange'] = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword: ComponentProps<'input'>['onChange'] = (e) => {
    setPassword(e.target.value);
  };

  return {
    apiError,
    errors,
    email,
    password,
    onSubmit,
    onChangeEmail,
    onChangePassword,
  };
};
