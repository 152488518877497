import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { description, title } from '@components/layout/SEO';
import { Login } from '@features/login';

const LoginPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>ログイン | {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <CookiesProvider>
        <Login />
      </CookiesProvider>
    </Layout>
  );
};

export default LoginPage;
